import { EQUIPMENTS_BY_REALM, EQUIPMENT_MAX_LEVELS_BY_REALM, STUDENTS_BY_REALM } from '@/constants/constants';
import { Equipment, MyStudent } from '@/models/models';

const ALL_STUDENTS = STUDENTS_BY_REALM.CN;

// Calculate items needed from the Students we OWN
// This DOES NOT take inventory into consideration!!
export function calculateAllStudentEquipmentNeeds(myStudents: MyStudent[]) {
  const itemsNeeded = {};
  // currentLevels

  for (let i = 0; i < myStudents.length; i++) {
    const myStudent = myStudents[i];
    const officialStudentDefinition = ALL_STUDENTS.find((allStudent) => allStudent.Id === +myStudent.id)!;
    // "Equipment": [
    //   "Hat",
    //   "Bag",
    //   "Watch"
    // ],
    // The official Equipment has all the equipment categories a student needs
    const studentEquipmentCategories = officialStudentDefinition.Equipment;

    for (let j = 0; j < myStudent.currentEquipmentLevels.length; j++) {
      const currentLevel = myStudent.currentEquipmentLevels[j];
      for (let tier = currentLevel; tier < EQUIPMENT_MAX_LEVELS_BY_REALM.zh[studentEquipmentCategories[j]]; tier++) {
        const equipment = EQUIPMENTS_BY_REALM.CN.find((equipment: Equipment) => {
          return (
            equipment.Category === studentEquipmentCategories[j] && equipment.Tier === tier + 1 && equipment.RecipeCost
          );
        })!;

        equipment.Recipe!.forEach((element) => {
          const [itemId, itemCount] = element;
          itemsNeeded[itemId] = (itemsNeeded[itemId] || 0) + itemCount;
        });
      }
    }
  }
  return itemsNeeded;
}
