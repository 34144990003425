import { MyStudent, OwnedInventoryMap } from '@/models/models';
import { atomWithStorage } from 'jotai/utils';

export const inventoryMapAtom = atomWithStorage<OwnedInventoryMap>('OwnedInventoryMap', {});

const MY_STUDENTS = 'MY_STUDENTS';
export const myStudentsAtom = atomWithStorage<MyStudent[]>(MY_STUDENTS, []);

// New atom for sorting order
export const sortOrderAtom = atomWithStorage('studentSortOrder', 'id');
