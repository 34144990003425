import { MAX_STAGE, STAGES } from '@/constants/constants';
import { ItemMap, Stage } from '@/models/models';
import { solve } from 'yalps';

function constructVariables(itemsNeeded) {
  const variables = {};
  // find all campaigns that drop the needed items
  console.debug(itemsNeeded);

  // Stage： 所有数据库中的关卡
  STAGES.forEach((cam: Stage) => {
    if (cam.Area > MAX_STAGE || cam.Difficulty === 1) {
      return;
    }

    const rewards = (cam.Rewards.Cn || cam.Rewards.Jp).filter(
      (reward) => reward.Type === 'Equipment' && reward.RewardType != 'FirstClear' && reward.Chance != undefined,
    );

    const isDropping = rewards.some((reward) => {
      return itemsNeeded[reward.Id];
    });
    if (!isDropping) {
      return;
    }

    // 如果当前关卡中，包含了所需要的 Item 的 DropChance
    // 则加入掉落列表的可能参数中

    // console.log(rewards);

    const itemDropTable = {};
    rewards.forEach((reward) => {
      itemDropTable[reward.Id] = reward.Chance;
    });
    variables[cam.Id] = {
      cost: cam.EntryCost[0][1],
      ...itemDropTable,
    };
  });

  return variables;
}

function shouldUserFarm(itemsNeeded: ItemMap, itemsOwned: ItemMap) {
  return Object.keys(itemsNeeded).some((itemId) => {
    const gap = itemsNeeded[itemId] - (itemsOwned[itemId] || 0);
    return gap > 0;
  });
}

export function calculateFarmingPlan(itemsNeeded: ItemMap, itemsOwned: ItemMap) {
  // Calculate min constraints. AKA least items we need to farm
  const userShouldFarm = shouldUserFarm(itemsNeeded, itemsOwned);
  if (!userShouldFarm) {
    return;
  }

  const constraints = {};
  for (const itemId in itemsNeeded) {
    const gap = itemsNeeded[itemId] - (itemsOwned[itemId] || 0);
    if (gap > 0) {
      constraints[itemId] = { min: gap };
    }
  }

  const variables = constructVariables(itemsNeeded);

  const model = {
    direction: 'minimize' as const,
    objective: 'cost',
    constraints,
    variables,
  };

  console.debug('LP Model', model);
  const solution = solve(model);
  console.debug('Proposed Solution', solution);
  return solution;
}
