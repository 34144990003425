import * as Sentry from '@sentry/react';

import { ApplicationRoutes } from './ApplicationRoutes';

Sentry.init({
  dsn: 'https://22b02ef12e30161e4019c87a6e150d4c@o4505957015289856.ingest.sentry.io/4505957016928256',
  integrations: [Sentry.replayIntegration()],
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function App() {
  return <ApplicationRoutes />;
}
