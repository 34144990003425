import { myStudentsAtom, sortOrderAtom } from '@/atoms';
import { EQUIPMENT_MAX_LEVELS_BY_REALM, STUDENTS_BY_REALM } from '@/constants/constants';
import { MyStudent, Student } from '@/models/models';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function getStudentUrl(studentId) {
  return `/avatars/${studentId}.webp`;
}

// 给后面选服务器留个口子
const ALL_STUDENTS = STUDENTS_BY_REALM.CN;

export default function Students() {
  const { t } = useTranslation();

  const [searchParam, setSearchParam] = useState(''); // 搜索 Students 的关键词
  const [myStudents, setMyStudents] = useAtom(myStudentsAtom); // 已经存储的学生
  const myStudentsIds = myStudents.map((student) => student.id); // 所有学生的 ID
  const [sortOrder, setSortOrder] = useAtom(sortOrderAtom); // 排序顺序

  function searchByStudentName(student: Student) {
    const possibleNames = [student.Name, student.PathName, student.FamilyName, student.PersonalName];
    if (searchParam === '') {
      return true;
    } else {
      return possibleNames.some((possibleName) => possibleName.toLowerCase().includes(searchParam.toLowerCase()));
    }
  }

  // 从存储的定义中，反向查找到完整学生的定义
  const ownedStudents = ALL_STUDENTS.filter((student) => myStudentsIds.includes(student.Id)).filter(
    searchByStudentName,
  );
  // 从存储的定义中，反向查找到还剩下的徐盛的定义
  const availableStudents = ALL_STUDENTS.filter((student) => !myStudentsIds.includes(student.Id)).filter(
    searchByStudentName,
  );

  // I know why this works but im not sure why the original order
  // does not work. Anyway I will come back to this later
  const sortedOwnedStudents = [...ownedStudents].sort((a, b) => {
    if (sortOrder === 'id') {
      return +a.Id - +b.Id;
    } else {
      const aIndex = myStudents.findIndex((s) => s.id === a.Id);
      const bIndex = myStudents.findIndex((s) => s.id === b.Id);
      return aIndex - bIndex;
    }
  });

  return (
    <div>
      <div className="mb-2">
        <label htmlFor="email" className="mb-2 block text-xl font-bold leading-6 text-gray-900">
          {t('searchStudentsInputLabel')}
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="search-students"
            value={searchParam || ''}
            onChange={(event) => {
              const enteredText = event.target.value;
              console.log(enteredText);
              setSearchParam(enteredText);
            }}
            id="search-students"
            className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Aru 爱鲁"
          />
        </div>
      </div>

      <div className="mb-2 flex items-center justify-between text-xl font-bold">
        <span>
          {t('ownedStudents')} ({ownedStudents.length})
        </span>
        <div className="flex space-x-2">
          <Popover id="options-dropdown" className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={` ${open ? 'text-gray-900' : 'text-gray-500'} group inline-flex items-center rounded-md bg-white px-3 py-2 text-base font-medium hover:text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span>{t('myStudentsSortingTitle')}</span>
                  <ChevronDownIcon
                    className={`${open ? 'text-gray-600' : 'text-gray-400'} ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-gray-500`}
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Popover.Panel className="absolute right-0 z-10 mt-3 w-56 transform px-4 sm:px-0">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-8 bg-white p-7">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-indigo-600"
                          checked={sortOrder === 'id'}
                          onChange={() => setSortOrder('id')}
                        />
                        <span className="ml-2">{t('sortById')}</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-indigo-600"
                          checked={sortOrder === 'added'}
                          onChange={() => setSortOrder('added')}
                        />
                        <span className="ml-2">{t('sortByAdded')}</span>
                      </label>
                    </div>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>

          <button
            id="reset-students-button"
            type="button"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              setMyStudents([]);
            }}
          >
            {t('resetMyStudents')}
          </button>
        </div>
      </div>

      <StudentList students={sortedOwnedStudents} setMyStudents={setMyStudents} />

      <div className="mb-2 mt-4 h-10 text-xl font-bold">
        {t('availableStudents')} ({Object.keys(availableStudents).length})
      </div>
      <StudentList students={availableStudents} setMyStudents={setMyStudents} />
    </div>
  );
}

function StudentList({ students, setMyStudents }: { students: Student[]; setMyStudents: any }) {
  const [myStudents] = useAtom(myStudentsAtom);

  return (
    <ul role="list" className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-6">
      {students.map((student: Student) => (
        <li
          key={student.Id}
          data-student-id={student.Id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
        >
          <div
            onClick={() => {
              setMyStudents((currentStudents: MyStudent[]) => {
                const existingStudent = currentStudents.find((s) => s.id === student.Id);

                if (existingStudent) {
                  return currentStudents.filter((s) => s.id !== student.Id);
                } else {
                  const newStudent = {
                    id: student.Id,
                    currentEquipmentLevels: [1, 1, 1],
                  } as MyStudent;
                  return [...currentStudents, newStudent];
                }
              });
            }}
            className="p6 flex w-full items-center justify-between space-x-6 p-6"
          >
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{student.Name}</h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{student.FamilyName}</p>
            </div>
            <img className="h-12 flex-shrink-0 rounded-full bg-gray-300" src={getStudentUrl(student.Id)} alt="" />
          </div>

          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {student.Equipment.map((equipment, index) => {
                return (
                  <EquipmentItem
                    key={index}
                    equipment={equipment}
                    student={student}
                    equipmentIndex={index}
                    setMyStudents={setMyStudents}
                    myStudents={myStudents}
                  />
                );
              })}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

// Student has equipment categories # attributes
// Student has equipment levels #

function EquipmentItem({
  equipment,
  student,
  equipmentIndex,
  setMyStudents,
  myStudents,
}: {
  equipment: string;
  student: Student;
  equipmentIndex: number;
  setMyStudents: React.Dispatch<React.SetStateAction<MyStudent[]>>;
  myStudents: MyStudent[];
}) {
  const { t } = useTranslation();
  const myStudent = myStudents.find((s) => s.id === student.Id);
  let IS_MAX = false;
  if (myStudent && myStudent.currentEquipmentLevels && myStudent.currentEquipmentLevels[equipmentIndex] !== undefined) {
    IS_MAX = myStudent.currentEquipmentLevels[equipmentIndex] === EQUIPMENT_MAX_LEVELS_BY_REALM.zh[equipment];
  }

  return (
    <div className="flex w-0 flex-1 text-gray-900 hover:text-blue-500">
      <span
        onClick={() => {
          setMyStudents((currentStudents) => {
            return currentStudents.map((currentStudent) => {
              if (currentStudent.id === student.Id) {
                const newEquipmentLevels = [...currentStudent.currentEquipmentLevels];
                if (newEquipmentLevels[equipmentIndex] < EQUIPMENT_MAX_LEVELS_BY_REALM.zh[equipment]) {
                  newEquipmentLevels[equipmentIndex] += 1;
                } else {
                  newEquipmentLevels[equipmentIndex] = 1;
                }
                return { ...currentStudent, currentEquipmentLevels: newEquipmentLevels };
              }
              return currentStudent;
            });
          });
        }}
        className={
          'relative -mr-px flex inline-flex w-0 flex-1 cursor-pointer select-none flex-col items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-1 text-sm font-semibold ' +
          `${IS_MAX ? ' text-red-800 hover:text-blue-500' : null}`
        }
      >
        <span>{t(equipment)}</span>
        <span>
          {myStudent?.currentEquipmentLevels && myStudent.currentEquipmentLevels[equipmentIndex] !== undefined
            ? myStudent.currentEquipmentLevels[equipmentIndex]
            : ''}
          <span className="text-xs">{IS_MAX ? `(${t('Max')})` : null}</span>
        </span>
      </span>
    </div>
  );
}
