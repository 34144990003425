import CN_STUDENTS_MAP from '@/assets/data/cn/students.json';
import Config from '@/assets/data/config.json';
import EQUIPMENTS_MAP from '@/assets/data/equipments.json';
import RawStages from '@/assets/data/stages.json';
import { BAConfig, Equipment, Stage, Student } from '@/models/models';

const CNRegionConfig = (Config as BAConfig).Regions.find((region) => region.Name == 'Cn');

// 目前 Stage == A 的关卡意义不明，看上去国服没有，其他服务器有
// 比如：https://www.youtube.com/watch?v=N_j30vqm5J4
// 目前 https://schaledb.com/stage 国服显示有 166 个，去掉意义不明的 A 关卡
// 3-A, 6-A, 9-A, 12-A, 15-A, 18-A
// 应该有 160 个
// 里面有点问题的是，不是每个 stage 都有 reward cn，那些没有的关卡，只好用 jp 的 rewards 来代替了
export const STAGES = (Object.values(RawStages) as Stage[]).filter((stage) => {
  return stage.Category === 'Campaign' && stage.Stage != 'A' && stage.Area <= CNRegionConfig!.CampaignMax;
});

export const STUDENTS_BY_REALM = {
  // CN: Object.values(CN_STUDENTS_MAP).filter((student) => student.IsReleased[2] == true) as Student[],
  CN: Object.values(CN_STUDENTS_MAP) as Student[],
};

const ALL_EQUIPMENTS = Object.values(EQUIPMENTS_MAP);

export const EQUIPMENTS_BY_REALM = {
  CN: ALL_EQUIPMENTS.filter((equipment) => equipment.IsReleased[2]) as Equipment[],
};

// 在一次遍历中计算 EQUIPMENT_CATEGORIES 和 EQUIPMENT_MAX_LEVELS_BY_REALM
const equipmentData = EQUIPMENTS_BY_REALM.CN.reduce(
  (acc, equip) => {
    if (equip.Tier > 1) {
      if (!acc.categories.has(equip.Category)) {
        acc.categories.add(equip.Category);
        acc.maxLevels[equip.Category] = equip.Tier;
      } else {
        acc.maxLevels[equip.Category] = Math.max(acc.maxLevels[equip.Category], equip.Tier);
      }
    }
    return acc;
  },
  {
    categories: new Set<string>(),
    maxLevels: {} as Record<string, number>,
  },
);

export const EQUIPMENT_CATEGORIES = Array.from(equipmentData.categories);

export const EQUIPMENT_MAX_LEVELS_BY_REALM = {
  zh: equipmentData.maxLevels,
};

const maxTier = Math.max(...Object.values(equipmentData.maxLevels));
export const TIERS = Array.from({ length: maxTier - 1 }, (_, i) => i + 2);

export const MAX_STAGE = CNRegionConfig!.CampaignMax;
