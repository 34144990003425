import { useEffect, useState } from 'react';

// Define a constant for the current banner version
const CURRENT_BANNER_VERSION = '2024-09-16'; // Update this when you have a new message
const message = `
开放了所有学生的选择， 方便提前囤货。增加了刷图页面更多信息
`;

export function Banner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const lastClosedVersion = localStorage.getItem('lastClosedBannerVersion');
    if (lastClosedVersion !== CURRENT_BANNER_VERSION) {
      setIsVisible(true);
    }
  }, []);

  const closeBanner = () => {
    setIsVisible(false);
    localStorage.setItem('lastClosedBannerVersion', CURRENT_BANNER_VERSION);
  };

  if (!isVisible) return null;

  return (
    <div className="bg-indigo-600">
      <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <p className="ml-3 truncate font-medium text-white">
              <span className="md:hidden">{message}</span>
              <span className="hidden md:inline">{message}</span>
            </p>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={closeBanner}
            >
              <span className="sr-only">Dismiss</span>
              {/* You can add an X icon here */}
              <span className="text-white">X</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
