export function Feedbacks() {
  return (
    <div className="px-1">
      <div className="flex items-center justify-between">
        <div className="mb-2 text-xl font-bold"> Feedbacks </div>
      </div>

      <div>
        <p> 有什么问题和建议可以发送邮件到 contact@bafarming.com，合理的建议都会尽快做的 </p>
        <p> 如果有好心人能提供个全通的小号做测试的话，感激不尽！ </p>

        <p className="mt-4 font-semibold">感谢以下朋友提供的宝贵意见：</p>
        <ul className="mt-2 list-disc pl-5">
          {thanksArray.map((person, index) => (
            <li key={index}>
              {person.name}：{person.contribution}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const thanksArray = [
  { name: 'Eleven', contribution: 'Bug 和测试，学生数据库改进，刷图页面信息改进' },
  { name: '恒稳', contribution: '增加了排序' },
];
