const zh = {
  translation: {
    Amulet: '护身符',
    availableStudents: '在野学生',
    Badge: '徽章',
    Bag: '背包',
    Charm: '护身符',
    farming: '刷图',
    Farming: '刷图',
    Gloves: '手套',
    Hairpin: '发夹',
    Hat: '帽子',
    inventory: '库存',
    Inventory: '库存',
    itemsGap: '目标差距',
    lastUpdateTime: '最后更新时间：',
    Max: '满',
    Necklace: '项链',
    ownedStudents: '已招募学生',
    searchStudentsInputLabel: '搜索学生',
    Shoes: '鞋',
    Students: '学生',
    submitFeedback: '意见和反馈',
    Watch: '手表',

    resetMyStudents: '重置',
    myStudentsSortingTitle: '排序',
    sortById: '根据学生 ID',
    sortByAdded: '根据加入顺序',
  },
};
const en = {
  translation: {
    Amulet: 'Amulet',
    availableStudents: 'Available Students',
    Badge: 'Badge',
    Bag: 'Bag',
    Charm: 'Charm',
    farming: 'Farming',
    Farming: 'Farming',
    Gloves: 'Gloves',
    Hairpin: 'Hairpin',
    Hat: 'Hat',
    inventory: 'Inventory',
    Inventory: 'Inventory',
    itemsGap: 'Items Gap',
    lastUpdateTime: 'Last Update Time:',
    Max: 'Max',
    Necklace: 'Necklace',
    ownedStudents: 'Owned Students',
    searchStudentsInputLabel: 'Search Students',
    Shoes: 'Shoes',
    Students: 'Students',
    submitFeedback: 'Submit Feedback',
    Watch: 'Watch',

    resetMyStudents: 'Reset',
    myStudentsSortingTitle: 'Sorting',
    sortById: 'Sort By ID',
    sortByAdded: 'Sort By Added',
  },
};

export const locales = {
  en: { ...en },
  zh: { ...zh },
};
