import EQUIPMENTS_MAP from '@/assets/data/equipments.json';
import RawStages from '@/assets/data/stages.json';
import { inventoryMapAtom, myStudentsAtom } from '@/atoms';
import { Equipment } from '@/models/official-models';
import { calculateFarmingPlan } from '@/utils/farming-calculator';
import { calculateAllStudentEquipmentNeeds } from '@/utils/student-util';
import { useAtom } from 'jotai';

function getEquipmentTierPieceIcon(equipment: Equipment) {
  return `/icons/equipments/equipment_icon_${equipment.Category.toLowerCase()}_tier${equipment.Tier}_piece.webp`;
}

export function Farming() {
  const [myStudents] = useAtom(myStudentsAtom);
  const [inventoryMap] = useAtom(inventoryMapAtom);

  const itemsNeeded = calculateAllStudentEquipmentNeeds(myStudents);
  const farmingPlan = calculateFarmingPlan(itemsNeeded, inventoryMap);
  const farmingStages = farmingPlan?.variables || [];

  function lookupDropInfos(stage) {
    const topRewards = (stage.Rewards.Cn || stage.Rewards.Jp)
      .filter(
        (reward) => reward.Type === 'Equipment' && reward.RewardType != 'FirstClear' && reward.Chance != undefined,
      )
      .slice(0, 3); // Limit to at most 3 elements

    const dropInfos = topRewards.map((reward) => {
      const equipment = EQUIPMENTS_MAP[reward.Id];
      return {
        equipment,
        icon: getEquipmentTierPieceIcon(equipment),
      };
    });
    return dropInfos;
  }

  return (
    <div className="px-1">
      <div className="mb-2 text-xl font-bold">刷图计算</div>

      <div className="mb-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {farmingStages.map((variable, index) => {
          const stage = RawStages[variable[0]];
          const stageName = `关卡${stage.Area}-${stage.Stage}`;
          const dropInfos = lookupDropInfos(stage);

          return (
            <div key={index} className="rounded-lg bg-white p-2 shadow">
              <p className="text-sm font-semibold text-gray-900">{stageName}</p>
              <div className="flex items-center justify-between">
                <p className="text-lg font-bold text-blue-600">{Math.ceil(variable[1])}</p>
                <div className="flex">
                  {dropInfos.map((dropInfo, idx) => (
                    <img
                      key={idx}
                      src={dropInfo.icon}
                      alt={dropInfo.equipment.Name}
                      title={dropInfo.equipment.Name}
                      className="ml-2 h-8 w-10 cursor-pointer transition-transform hover:scale-110"
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {farmingStages.length > 0 && (
        <div className="rounded-lg bg-gray-100 p-4 shadow">
          <p className="text-lg font-semibold text-gray-900">总体力消耗</p>
          <p className="text-2xl font-bold text-blue-600">
            {farmingStages.reduce((accumulator, currentValue) => accumulator + Math.ceil(currentValue[1]) * 10, 0)}
          </p>
        </div>
      )}
    </div>
  );
}
