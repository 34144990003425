import { inventoryMapAtom, myStudentsAtom } from '@/atoms';
import { EQUIPMENTS_BY_REALM, EQUIPMENT_CATEGORIES, TIERS } from '@/constants/constants';
import { calculateAllStudentEquipmentNeeds } from '@/utils/student-util';
import { useAtom } from 'jotai';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function getEquipmentTierPieceIcon(equipmentCategory, tier) {
  return `/icons/equipments/equipment_icon_${equipmentCategory.toLowerCase()}_tier${tier}_piece.webp`;
}

function InventoryMatrix() {
  const [inventoryMap, setInventoryMap] = useAtom(inventoryMapAtom);
  const { t } = useTranslation();

  const CN_EQUIPMENTS = EQUIPMENTS_BY_REALM.CN;

  return (
    <div className="w-full">
      <div className="mb-2 text-xl font-bold"> {t('inventory')} </div>
      <div className="flex h-8">
        <div className="w-16 border-2"></div>
        {TIERS.map((tier) => {
          return (
            <div key={tier} className="w-20 border-2 border-solid border-indigo-600 text-center">
              T{tier}
            </div>
          );
        })}
      </div>

      {EQUIPMENT_CATEGORIES.map((equipmentCategory) => {
        return (
          <div key={equipmentCategory} className="flex h-auto border-b-2">
            <div className="flex w-16 items-center text-sm">{t(equipmentCategory)}</div>
            {TIERS.map((tier, index) => {
              const equipment = CN_EQUIPMENTS.find((equipment) => {
                return equipment.Category === equipmentCategory && equipment.Tier === tier && !equipment.RecipeCost;
              })!;

              return (
                <div key={index} className="flex h-20 w-20 flex-col items-center justify-center">
                  <img className="h-10 w-12" src={getEquipmentTierPieceIcon(equipmentCategory, tier)} />
                  <input
                    type="number"
                    min={0}
                    className="block w-16 rounded-md border-0 text-center text-sm leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    value={inventoryMap[equipment.Id] || 0}
                    onChange={(e) => {
                      setInventoryMap((currentInventory) => ({
                        ...currentInventory,
                        [equipment.Id]: e.target.value,
                      }));
                    }}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function EquipmentMatrix() {
  const [myStudents] = useAtom(myStudentsAtom);
  const [inventoryMap] = useAtom(inventoryMapAtom);

  const itemsNeeded = calculateAllStudentEquipmentNeeds(myStudents);
  const { t } = useTranslation();
  const CN_EQUIPMENTS = EQUIPMENTS_BY_REALM.CN;

  return (
    <div className="w-full">
      <div className="mb-2 text-xl font-bold"> {t('itemsGap')} </div>
      <div className="flex h-8">
        <div className="w-16 border-2"></div>
        {TIERS.map((tier) => {
          return (
            <div key={tier} className="w-20 border-2 border-solid border-indigo-600 text-center">
              T{tier}
            </div>
          );
        })}
      </div>
      {EQUIPMENT_CATEGORIES.map((equipmentCategory) => {
        return (
          <div key={equipmentCategory} className="flex h-auto border-b-2">
            <div className="flex w-16 items-center text-sm">{t(equipmentCategory)}</div>
            {TIERS.map((tier, index) => {
              const equipment = CN_EQUIPMENTS.find((equipment) => {
                return equipment.Category === equipmentCategory && equipment.Tier === tier && !equipment.RecipeCost;
              })!;

              return (
                <div key={index} className="flex h-20 w-20 flex-col items-center justify-center">
                  <img className="h-10 w-12" src={getEquipmentTierPieceIcon(equipmentCategory, tier)} />
                  <div className="flex h-6 items-center justify-center text-sm">
                    {itemsNeeded[equipment.Id] ? (
                      itemsNeeded[equipment.Id] - (inventoryMap[equipment.Id] || 0) <= 0 ? (
                        <Check className="text-sky-600" />
                      ) : (
                        itemsNeeded[equipment.Id] - (inventoryMap[equipment.Id] || 0)
                      )
                    ) : (
                      0
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default function InventoryPage() {
  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="w-full lg:w-1/2">
        <InventoryMatrix />
      </div>
      <div className="w-full lg:w-1/2">
        <EquipmentMatrix />
      </div>
    </div>
  );
}
